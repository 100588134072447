<template>
  <div class="project">
    <div class="main">
      <div class="content">
        <dw-filter :mode="'light'" @change="filterChange"></dw-filter>
        <div class="info">
          <!-- <div class="imgInfo">
            <h5>图片信息</h5>
            <p>编号：897423</p>
            <p>摄影：卡萨帝专职摄影师</p>
            <p>尺寸：1920*1080</p>
            <p>标题：全屋空气场景A23</p>
          </div> -->
          <div class="userInfo">
            <p v-if="slide.user"><img src="@/assets/label/1.png"> {{slide.user}}</p>
            <p v-if="slide.address"><img src="@/assets/label/2.png"> {{slide.address}}</p>
            <p v-if="slide.people"><img src="@/assets/label/3.png"> {{slide.people}}</p>
            <p v-if="slide.home"><img src="@/assets/label/4.png"> {{slide.home}}</p>
            <p v-if="slide.goods"><img src="@/assets/label/5.png"> {{slide.goods}}</p>
          </div>
          <div class="tagInfo">
            <h5>标签</h5>
            <div>
              <a-tag v-for="(item,i) in tagList" :key="i">{{item}}</a-tag>
            </div>
          </div>
        </div>
        <div class="img-list">
          <div class="img-item" v-for="(item,url) in imgList" :key="url">
            <img :src="item.url1800" referrerpolicy="no-referrer">
            <div class="action">
              <div class="btn view" @click="viewImg(url)">查看大图</div>
              <div class="btn down" @click="downImg(item)">立即下载</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a-modal v-model="visible" title="查看大图" :dialog-style="{ top: '20px' }" :footer="null" :width="'90vw'">
      <div>
        <a-carousel ref="slider" arrows :after-change="onChange">
          <div slot="prevArrow" slot-scope="props" class="custom-slick-arrow" style="left: 10px;zIndex: 1">
            <a-icon type="left-circle" />
          </div>
          <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 10px">
            <a-icon type="right-circle" />
          </div>
          <div class="carousel-item" v-for="(item,url) in viewList" :key="url">
            <img :src="item.url1800">
          </div>

        </a-carousel>
      </div>
    </a-modal>
  </div>
</template>

<script>
  // @ is an alias to /src
  import { onBeforeMount } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import DwFilter from '@/components/DwFilter.vue'
  // import ListMixin from '@/mixin/list.js'
  export default {
    name: 'ProjectDetail',
    mixins: [],
    components: {
      DwFilter
    },
    data() {
      return {
        route: '',
        router: '',
        tagList: [],
        visible: false,
        imgList: [],
        viewList: [],
        slide: {},
        category: null,
        commonTag: [],
        specialTag: [],
        searchInput: null,
        body: {
          categoryAggs: 200
        }
      }
    },
    setup() {
      const route = useRoute();
      const reload = () => {

      }
      onBeforeMount(() => {
      });
      const router = useRouter();
      return {
        router
      }

    },
    beforeMount() {
      this.category = this.$route.query.category;
      this.firstUrl = this.$route.query.firstUrl;
    },
    mounted() {
      this.getImage();
    },
    methods: {
      filterChange(ev) {
        console.log(ev)
        this.commonTag = ev.commonTag;
        this.specialTag = ev.specialTag;
        this.searchInput = ev.searchInput;
        this.$router.push('/list')
        // this.getImage();
      },
      getImage() {
        let data = [];
        this.imgList = [];
        this.tagList = [];
        let size = [600, 1800, 2400];
        console.log(this.commonTag);
        this.body['searchWord'] = this.searchInput && this.searchInput !== '' ? this.searchInput : null;
        this.body['commonTag'] = this.commonTag.length ? this.commonTag.join() : null;
        this.body['specialTag'] = this.specialTag.length ? this.specialTag.join() : null;
        this.body['category'] = this.category;
        this.$net.getImg(this.body).then(res => {

          if (res.page.content.length) {
            data = res.page.content;
            if (this.firstUrl) {
              const array = JSON.parse(JSON.stringify(data))
              let index = data.findIndex(v => {
                return v['url'] == this.firstUrl
              })
              const list1 = array.splice(index);
              data = [...list1, ...array]
            }
            this.imgList = data;

            this.imgList.map(v => {
              size.map(u => {
                let pre = v['url'].split('.' + v['ext'])[0];
                v['url' + u] = pre + '_' + u + '.' + v['ext'];
              })
              v['url'] = 'http://www.casartephoto.cn' + v['url'].split('http://photo.casarte.com')[1]
            })
            let arr = this.imgList[0].tag.split(',');
            this.getDescription(arr);
          } else {
            // this.slide = {};
          }
        }).catch(res => {

        })
      },


      getDescription(arr) {
        arr.map(v => {
          if (v.indexOf('编号') !== -1) {
            this.slide['no'] = v;
          } else if (v.indexOf('用户') !== -1) {
            this.slide['user'] = v;
          } else if (v.indexOf('省市') !== -1) {
            //地点后面展示省市
            this.slide['address'] = '地点' + '：' + v.split('：')[1];
          } else if (v.indexOf('家庭') !== -1 && v.indexOf('家庭人口') == -1) {
            this.slide['people'] = v;
          } else if (v.indexOf('住所') !== -1) {
            this.slide['home'] = v;
          } else if (v.indexOf('产品') !== -1) {
            this.slide['goods'] = v;
          }
          if (v.indexOf('：') == -1 || (v.indexOf('：') !== -1 && (v.split('：')[0] == '价格区间' || v.split('：')[0] == '家庭人口'))) {
            this.tagList.push(v);
          }

        })
        console.log(this.slide);
      },
      onChange(ev) {

      },
      downImg(item) {
        console.log('下载')
        let url = 'http://www.casartephoto.cn:8901/app/downImg?url=' + item['url'] + '&name=' + '卡萨帝照片数据库：' + this.slide.address.split('：')[1] + this.slide.user.split('：')[1] + '.' + item['ext']

        window.open(url);
        this.$message.info('下载中，请稍候...');
       
        // var alink = document.createElement("a");
        // alink.href = 'http://www.casartephoto.cn'+item['url'].split('http://photo.casarte.com')[1];
        // alink.download = item['name']; //图片名
        // alink.click();
        /////////////////////////////////////////////
        
        // let name = '卡萨帝照片数据库：'+this.slide.address.split('：')[1]+this.slide.user.split('：')[1]
        // const image = new Image();
        // // 解决跨域 Canvas 污染问题
        // image.src = item['url']
        // image.setAttribute('crossOrigin', 'anonymous');      
        // image.onload = function () {
        //   const canvas = document.createElement('canvas');
        //   canvas.width = image.width;
        //   canvas.height = image.height;
        //   const context = canvas.getContext('2d');
        //   context.drawImage(image, 0, 0, image.width, image.height);
        //   const url = canvas.toDataURL(); // 得到图片的base64编码数据
        //   const a = document.createElement('a'); // 生成一个a元素
        //   const event = new MouseEvent('click'); // 创建一个单击事件                    
        //   a.download = name; // 设置图片名称    
        //   a.href = url; // 将生成的URL设置为a.href属性
        //   a.dispatchEvent(event); // 触发a的单击事件
        // };
      },
      viewImg(index) {
        const newList = JSON.parse(JSON.stringify(this.imgList))
        const list1 = newList.splice(index);
        this.viewList = [...list1, ...newList]
        this.visible = true;
        this.$nextTick(() => {
          this.$refs.slider.goTo(0, true)
        })

      }
    }
  }
</script>
<style lang="less" scoped type="text/css">
  .carousel-item {
    width: 100%;
    height: 800px;
    display: flex !important;
    justify-content: center;
    align-items: center;

    img {
      display: block;
      max-height: 100%;
      max-width: 100%;
    }
  }


  .custom-slick-arrow {
    width: 40px;
    height: 40px;
    font-size: 40px;
    color: #000;
    opacity: 0.3;
  }

  .custom-slick-arrow:before {
    display: none;
  }

  .custom-slick-arrow:hover {
    color: #000;
    opacity: 0.5;
  }

  .project {
    position: relative;
    padding-top:60px;
    .main {
      background: #f5f5f5;
      padding: 20px 0 10px 0;
      margin: 0 auto;
      width: 100%;
      left: 0;
      top: 0;

      .content {
        width: 1200px;
        margin: 0 auto;

        .info {
          display: flex;
          padding: 20px 50px;

          h5 {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 15px;
          }

          p {
            margin-bottom: 10px;
          }

          .imgInfo {
            width: 25%;
          }

          .userInfo {
            width: 30%;

            img {
              margin-top: -2px;
            }
          }
          .tagInfo{
            width: 70%;
            padding-left:10px;
          }
        }

        .img-list {
          padding: 0 20px;

          .img-item {
            margin-bottom: 20px;
            border: 1px solid #999;

            img {
              width: 100%;
            }

            .action {
              display: flex;
              justify-content: center;
              padding: 10px 0;

              .btn {
                color: #fff;
                padding: 5px 50px;
                margin: 0 10px;
                border-radius: 5px;
                cursor: pointer;
              }

              .view {
                background-color: #68227F;
              }

              .down {
                background-color: @theme-color;
              }
            }
          }

          @media screen and (max-width:1366px) {
            .img-list {
              padding: 0 20px;
            }
          }
        }

      }
    }
  }
</style>
<style lang="less" scoped type="text/css">
  @import url('@/mixin/base.less');
</style>