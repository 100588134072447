import request from './request'
import axios from 'axios'
const url = '/athena/forward/FFA049990E0483EA36267808D020174D6F3C18962FEEC31C14FB555F53A2CE50';
const feedbackUrl = '/athena/forward/C901A553D347665821930D35C3B4B32640CFDACB2DD0BE1D3AB1A05D28DC9208B71BB599ECF55A816CF4117641DE4644'
const extra = {
	code: '75ab3cfad7d040f5809ed854505c31b8',
	siteId: 21,
	pageSize: 1000,
	pageNumber: 1,
}
export function homeSlide() {
	console.log(extra)
	return request({
		url: url,
		method: 'get',
		params: {
			category: '首页轮播图',
			...extra,
			pageSize: 10,
			pageNumber: 1,
			mediaId: 'asc'
		}
	})
}

export function getImg(data) {
	let obj = Object.assign({}, extra, data)
	return request({
		url: url,
		method: 'get',
		params: obj,

	})
}

export function feedback(data) {
	return request({
		url: feedbackUrl,
		method: 'post',
		data: data
	})
}
export function addPv(data) {
	const http = axios.create({
		baseURL: 'http://47.95.9.3:8901'
	});
	return http.post('/app/pv', data)
}
export function getPv(data) {
	const http = axios.create({
		baseURL: 'http://47.95.9.3:8901'
	});

	return http.get('/app/pv', data)
}
