<template>
	<div class="project">
		<div class="main">
			<div class="content">
				<keep-alive>
					<dw-filter :mode="'light'" :formData="formData" :inputData="searchInput" @change="filterChange"></dw-filter>
				</keep-alive>
				<!-- <dw-filter :mode="'light'" @change="filterChange"></dw-filter> -->
				<div style="height:20px"></div>
				<dw-img :initReady="initReady" :imgList="imgList"></dw-img>
			</div>
		</div>
		<a-back-top />
	</div>
</template>

<script>
	// @ is an alias to /src
	import DwFilter from '@/components/DwFilter.vue'
	import DwImg from '@/components/DwImg.vue'
	import ListMixin from '@/mixin/list.js'
	export default {
		name: 'List',
		mixins: [ListMixin],
		components: {
			DwFilter,
			DwImg
		},
		data() {
			return {
				active: '',
				imgList: [],
				searchInput: null,
				defaultTag: [],
				commonTag: [],
				specialTag:[],
				body: {
					categoryAggs: 200
				},
				formData: []
			}
		},
		created(){
				
			this.commonTag = localStorage.commonTag ? JSON.parse(localStorage.commonTag) : [];	
			this.orCommonTag = localStorage.orCommonTag ? JSON.parse(localStorage.orCommonTag) : [];
			this.specialTag = localStorage.specialTag ? JSON.parse(localStorage.specialTag) : [];
			this.orSpecialTag = localStorage.orSpecialTag ? JSON.parse(localStorage.orSpecialTag) : [];
			this.searchInput = localStorage.searchInput ? localStorage.searchInput : null,
			this.formData = localStorage.filterForm? JSON.parse(localStorage.filterForm) : [];		
		},
		

		methods: {

			loadMore(ev) {
				
			}
		}
	}
</script>
<style lang="less" scoped type="text/css">
	.project {
		position: relative;
		padding-top: 60px;
		.main {
			/* min-height: 100vh; */
			background: #f5f5f5;
			padding: 20px 0 10px 0;
			margin: 0 auto;
			width: 100%;
			left: 0;
			top: 0;

			.content {
				width: 1200px;
				margin: 0 auto;
			}

			.cate-list {
				width: 1100px;
				margin: 20px auto 0 auto;
				display: flex;

				.img {
					/*	          overflow: hidden;*/
					margin-bottom: 10px;
					width: 100%;
					text-align: center;
				}

				img {
					width: 91px;
					transition: all 0.6s;
					cursor: pointer;

					&:hover {
						transform: scale(1.1);
					}
				}

				p {
					margin-top: 10px;
				}

			}
			@media screen and (max-width:1280px) {
				.content {
					width: 100%;
				}
			}

		
		}
	}
</style>