<template>
	<div class="header">
		<div class="nav">
			<router-link to="/"> <img src="@/assets/action/line.png"> 首页</router-link> <span class="divider">|</span>
			<router-link to="/product">高端产品</router-link> <span class="divider">|</span>
			<router-link to="/whole-house">全屋方案</router-link> <span class="divider">|</span>
			<router-link to="/project-list">智慧空间</router-link> <span class="divider">|</span>
			<router-link to="/life">装修风格</router-link>
		</div>
		<div class="logo">
			<img src="@/assets/logo1.png">
		</div>
		<div class="ques">
			<router-link to="/ques"> <img src="@/assets/ques/user.png"> 问题反馈</router-link>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'DwHeader',
		props: {
			msg: String
		}
	}
</script>

<style lang="less" scoped>
	.header {
		position:fixed;
		background: #fff;
        width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		z-index: 999;
   
		.nav {
			padding: 20px;
			z-index: 999;
			margin-left: 30px;

			.divider {
				color: @theme-color;
				overflow: hidden;
				display: inline-block;
				width: 3px;
				height: 14px;
			}

			a {
				font-weight: 400;
				color: #474545;
				display: inline-block;
				margin: 0 10px;

			}

			.router-link-exact-active {
				color: @theme-color;
				border-bottom: 2px solid @theme-color;
			}
		}

		.ques {
			margin-right: 30px;
			cursor: pointer;
			z-index: 999;

			a {
				color: #474545;
			}

			img {
				margin-top: -3px;
			}
		}

		.logo {
			position: absolute;
			text-align: center;
			width: 100%;
			img{
				width:36%;
			}
		}

		@media screen and (max-width:1366px) {
			.nav {
				margin-left: 0;
			}

		}

		

		@media screen and (max-width:1024px) {
            
			.logo {
				padding-left: 18%;
			}

			.logo img {
				width: 46%;
			}
		}
		@media screen and (max-width:768px) {
            .logo {
				padding-left: 28%;
			}
			
			
		}
	}
</style>