<template>
	<div class="project">
		<div class="main">
			<div class="content">
				<dw-filter :mode="'light'" @change="filterChange"></dw-filter>
				<div class="cate-list">
					<div class="img" @click="setActive('zsct')">
						<img v-if="active=='zsct'" src="@/assets/life/1-a.png">
						<img v-else src="@/assets/life/1.png">
					</div>
					<div class="img img1" @click="setActive('zsxzs')">
						<img v-if="active=='zsxzs'" src="@/assets/life/2-a.png">
						<img v-else src="@/assets/life/2.png">
					</div>
					<div class="img img2" @click="setActive('osgd')">
						<img v-if="active=='osgd'" src="@/assets/life/3-a.png">
						<img v-else src="@/assets/life/3.png">
					</div>
					<div class="img img1" @click="setActive('osjo')">
						<img v-if="active=='osjo'" src="@/assets/life/4-a.png">
						<img v-else src="@/assets/life/4.png">
					</div>
					<div class="img img2" @click="setActive('xdjy')">
						<img v-if="active=='xdjy'" src="@/assets/life/5-a.png">
						<img v-else src="@/assets/life/5.png">
					</div>
					<div class="img img2" @click="setActive('xdqs')">
						<img v-if="active=='xdqs'" src="@/assets/life/6-a.png">
						<img v-else src="@/assets/life/6.png">
					</div>
					<div class="img img1" @click="setActive('ms')">
						<img v-if="active=='ms'" src="@/assets/life/7-a.png">
						<img v-else src="@/assets/life/7.png">
					</div>
					<div class="img img1" @click="setActive('fs')">
						<img v-if="active=='fs'" src="@/assets/life/8-a.png">
						<img v-else src="@/assets/life/8.png">
					</div>



				</div>
				<dw-img style="margin-top: 30px;" :initReady="initReady" :imgList="imgList"></dw-img>
			</div>
		</div>
		<a-back-top />
	</div>
</template>

<script>
	// @ is an alias to /src
	import DwFilter from '@/components/DwFilter.vue'
	import DwImg from '@/components/DwImg.vue'
	import ListMixin from '@/mixin/list.js'
	export default {
		name: 'Life',
		mixins:[ListMixin],
		components: {
			DwFilter,
			DwImg
		},
		data() {
			return {
				active: 'zsct',
				imgList: [],
				searchInput: null,
				classifyObj: {
					zsct: '中式（传统）',
					zsxzs: '中式（新中式）',
					osgd: '欧式（古典）',
					osjo: '欧式（简欧）',
					xdjy: '现代（简约）',
					xdqs: '现代（轻奢）',
					ms: '美式',
					fs: '法式'

				},
				defaultTag: ['中式（传统）'],
				// defaultTag: ['中式（传统）', '中式（新中式）', '欧式（古典）', '欧式（简欧）', '现代（简约）','现代（轻奢）','美式','法式'],
				body: {
					categoryAggs: 200
				}
			}
		},
		methods: {
			
			loadMore(ev) {
				console.log(ev)
			}
		}
	}
</script>
<style lang="less" scoped type="text/css">
	.project {
		position: relative;
		padding-top: 60px;
		.main {
			/* min-height: 100vh; */
			background: #f5f5f5;
			padding: 20px 0 10px 0;
			margin: 0 auto;
			width: 100%;
			left: 0;
			top: 0;

			.content {
				width: 1200px;
				margin: 0 auto;
			}

			.cate-list {
				width: 1100px;
				margin: 20px auto 0 auto;
				display: flex;
				.img {
					/*	          overflow: hidden;*/
					margin-bottom: 10px;
					width: 100%;
					text-align: center;
				}

				img {
					width: 92px;
					transition: all 0.6s;
					cursor: pointer;

					&:hover {
						transform: scale(1.1);
					}
				}

				p {
					margin-top: 10px;
				}

			}
			@media screen and (max-width:1280px) {
				.cate-list {
					width: 90%;
				}
			}
			@media screen and (max-width:1280px) {
				.cate-list {
					width: 90%;
				}
				img {
					width: 75px!important;
					transition: all 0.6s;
					cursor: pointer;

					&:hover {
						transform: scale(1.1);
					}
				}
			}
		}
	}
</style>
<style lang="less" scoped type="text/css">
	@import url('@/mixin/base.less');
</style>