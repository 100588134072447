<template>
	<div class="project">
		<div class="main">
			<div class="content">
				<dw-filter :mode="'light'" @change="filterChange"></dw-filter>
				<a-divider style="color:#999;">热门单品</a-divider>
				<div class="cate-list">
					<div class="img" @click="setActive('fridge')">
						<img v-if="active=='fridge'" src="@/assets/product/1.png">
						<img v-else src="@/assets/product/1-a.png">
						<p>冰箱</p>
					</div>
					<div class="img img1" @click="setActive('ice')">
						<img v-if="active=='ice'" src="@/assets/product/8.png">
						<img v-else src="@/assets/product/8-a.png">
						<p>冰吧</p>
					</div>
					<div class="img img1" @click="setActive('pine')">
						<img v-if="active=='pine'" src="@/assets/product/9.png">
						<img v-else src="@/assets/product/9-a.png">
						<p>酒柜</p>
					</div>
					<div class="img img1" @click="setActive('cold')">
						<img v-if="active=='cold'" src="@/assets/product/12.png">
						<img v-else src="@/assets/product/12-a.png">
						<p>冷柜</p>
					</div>
					<div class="img img2" @click="setActive('fash')">
						<img v-if="active=='fash'" src="@/assets/product/2.png">
						<img v-else src="@/assets/product/2-a.png">
						<p>洗衣机</p>
					</div>
					<div class="img img2" @click="setActive('air')">
						<img v-if="active=='air'" src="@/assets/product/3.png">
						<img v-else src="@/assets/product/3-a.png">
						<p>空调</p>
					</div>
					<div class="img img1" @click="setActive('tv')">
						<img v-if="active=='tv'" src="@/assets/product/6.png">
						<img v-else src="@/assets/product/6-a.png">
						<p>电视</p>
					</div>
					<div class="img img2" @click="setActive('water')">
						<img v-if="active=='water'" src="@/assets/product/5.png">
						<img v-else src="@/assets/product/5-a.png">
						<p>热水器</p>
					</div>
					
					<div class="img img1" @click="setActive('wash')">
						<img v-if="active=='wash'" src="@/assets/product/7.png">
						<img v-else src="@/assets/product/7-a.png">
						<p>洗碗机</p>
					</div>
					<div class="img img1" @click="setActive('hot')">
						<img v-if="active=='hot'" src="@/assets/product/4.png">
						<img v-else src="@/assets/product/4-a.png">
						<p>厨电</p>
					</div>
					<div class="img img1" @click="setActive('filter')">
						<img v-if="active=='filter'" src="@/assets/product/10.png">
						<img v-else src="@/assets/product/10-a.png">
						<p>净水</p>
					</div>
					<div class="img img1" @click="setActive('xiaojiadian')">
						<img v-if="active=='xiaojiadian'" src="@/assets/product/11.png">
						<img v-else src="@/assets/product/11-a.png">
						<p>小家电</p>
					</div>
					
				</div>
				<dw-img :initReady="initReady" :imgList="imgList"></dw-img>
			</div>
		</div>
		<a-back-top />
	</div>
</template>

<script>
	// @ is an alias to /src
	import DwFilter from '@/components/DwFilter.vue'
	import DwImg from '@/components/DwImg.vue'
	import ListMixin from '@/mixin/list.js'
	export default {
		name: 'Product',
		mixins:[ListMixin],
		components: {
			DwFilter,
			DwImg
		},
		data() {
			return {
				active: 'fridge',
				imgList: [],
				searchInput: null,
				classifyObj:{
					fridge:'冰箱',
					ice:'冰吧',
					pine:'酒柜',
					cold:'冷柜',
					fash:'洗衣机',
					air:'空调',
					tv:'电视',
					water:'热水器',
					wash:'洗碗机',
					hot:'厨电',					
					filter:'净水',
					xiaojiadian:'小家电'
					
				},
				// defaultTag: ['冰箱', '冰吧', '酒柜', '冷柜', '洗衣机', '空调', '电视', '热水器','洗碗机','厨电','净水','小家电'],
				defaultTag: ['冰箱'],
				commonTag:[],
				body: {
					categoryAggs: 200
				}
			}
		},
		
		methods: {
			
			loadMore(ev) {
				console.log(ev)
			}
		}
	}
</script>

<style lang="less" scoped type="text/css">
	.project {
		position: relative;
		padding-top: 60px;
		.main {
			/* min-height: 100vh; */
			background: #f5f5f5;
			padding: 20px 0 10px 0;
			margin: 0 auto;
			width: 100%;
			left: 0;
			top: 0;

			.content {
				width: 1200px;
				margin: 0 auto;
			}

			.cate-list {
				width: 1100px;
				margin: 20px auto 0 auto;
				display: flex;

				.img {
					/*	          overflow: hidden;*/
					margin-bottom: 10px;
					width: 100%;
					text-align: center;
				}

				img {
					width: 75px;
					transition: all 0.6s;
					cursor: pointer;

					&:hover {
						transform: scale(1.1);
					}
				}

				p {
					margin-top: 10px;
				}

			}
			@media screen and (max-width:1280px) {
				.cate-list {
					width: 90%;
				}
			}
			@media screen and (max-width:1280px) {
				.cate-list {
					width: 90%;
				}
				img {
					width: 75px!important;
					transition: all 0.6s;
					cursor: pointer;

					&:hover {
						transform: scale(1.1);
					}
				}
			}
		}
	}
</style>
<style lang="less" scoped type="text/css">
	@import url('@/mixin/base.less');
</style>