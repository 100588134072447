<template>
	<div class="footer">

		<div class="nav">
			<div ref="contact" class="contact" @mouseenter="wechatShow=true" @mouseleave="wechatShow=false"
				@click="wechatShow=true">联系我们</div>
			<router-link to="/law">法律声明</router-link>
			<router-link to="/ques">问题反馈</router-link>
			<router-link to="/service">服务条款</router-link>
		</div>
		<div class="copyright">
			Copyright 2011-2022 www.casartephoto.cn All Rights Reserved 鲁ICP备2022003136号
		</div>

		<div class="logo">
			关注 Casarte
			<img ref="weibo" class="weibo" style="position:relative;z-index:99" src="@/assets/action/weibo.png" @mouseenter="weiboShow=true"
				@mouseleave="weiboShow=false" @click="weiboShow=true">
			<img ref="wechat" class="wechat" style="position:relative;z-index:99" src="@/assets/action/wechat.png" @mouseenter="show=true"
				@mouseleave="show=false" @click="show=true">
			<div ref="weiboModal" v-if="weiboShow" class="cs-mask" @click="weiboShow=false">
				<img class="big-weibo-code" :style="{right:weiboRight,bottom:weiboBottom}" src="@/assets/weibo1.png">
			</div>
			<div ref="wechatModal" v-if="show" class="cs-mask" @click="show=false">
				<img class="big-code" :style="{right:wechatRight,bottom:weiboBottom}" src="@/assets/code.jpg">
			</div>
			<!-- <img class="code" src="@/assets/code.jpg" @mouseover="show=true" @mouseleave="show=false"> -->
			<!-- <a-modal title="" :visible="weiboShow" :closable="false" :mask="false" :maskClosable="true"
					:footer="null" :width="180" ref="weiboModal" :dialog-style="weiboDialogStyle"
					:bodyStyle="{padding:0}" class="cs-modal" @cancel="weiboShow=false">
					<img class="big-weibo-code" src="@/assets/weibo1.png">
				</a-modal>
				<a-modal title="" :visible="show" :closable="false" :mask="false" :maskClosable="true" :footer="null"
					:width="180" ref="wechatModal" :dialog-style="wechatDialogStyle" :bodyStyle="{padding:0}"
					class="cs-modal" @cancel="show=false">
					<img ref="wechatModal" class="big-code" src="@/assets/code.jpg">
				</a-modal> -->
		</div>
		<!-- <a-modal title="" :visible="wechatShow" :mask="false" :maskClosable="true" :footer="null"
				:width="wechatWidth" ref="contactModal" :bodyStyle="wechatBodyStyle" :dialog-style="csDialogStyle"
				class="cs-modal" @cancel="wechatShow=false">
				<div class="modal-left">
					<img class="tel-img" src="@/assets/tel.png">
					<div class="tel-cont">
						<div class="tel-title">客服热线</div>
						<div class="tel">4006-399-699</div>
					</div>
				</div>
				<div class="modal-right">
					<div class="code-box">
						<img src="@/assets/code.jpg">
					</div>
					<div class="wechat-service">
						<div class="wechat-title">微信服务号</div>
						<div class="info">关注微信服务号在线咨询互动 ，</br>了解卡萨帝最新动态</div>
					</div>

				</div>
			</a-modal> -->
		<div ref="contactModal" v-if="wechatShow" class="cs-mask" @click="wechatShow=false">
			<div class="cs-modal" :style="{bottom:weiboBottom}">
				<div class="modal-left">
					<img class="tel-img" src="@/assets/tel.png">
					<div class="tel-cont">
						<div class="tel-title">客服热线</div>
						<div class="tel">4006-399-699</div>
					</div>
				</div>
				<div class="modal-right">
					<div class="code-box">
						<img src="@/assets/code.jpg">
					</div>
					<div class="wechat-service">
						<div class="wechat-title">微信服务号</div>
						<div class="info">关注微信服务号在线咨询互动 ，</br>了解卡萨帝最新动态</div>
					</div>

				</div>
			</div>
		</div>

	</div>
</template>
<script>
	export default {
		name: 'DwHeader',
		props: {
			msg: String
		},
		data() {
			return {
				show: false,
				weiboShow: false,
				wechatShow: false,
				wechatBodyStyle: {
					display: 'flex'
				},
				wechatDialogStyle: {
				},
				csDialogStyle: {
				},
				weiboDialogStyle: {},
				wechatWidth: 1200,
				wechatTop: '200px',
				weiboRight: '0',
				weichatRight: '0',
				weiboBottom:'0'
			}
		},
		mounted() {			
			// let _this = this;
			//点击页面其他地方，弹窗隐藏
			// document.addEventListener('click', function (e) {
			// 	console.log(e.target.className, typeof (e.target.className))
			// 	console.log(_this.wechatShow)
			// 	if (e.target.className !== 'contact') {
			// 		_this.wechatShow = false;
			// 	}
			// 	//   if((_this.$refs.contactModal&&!!_this.$refs.contactModal.contains(e.target)) || (_this.$refs.contact&&!!_this.$refs.contact.contains(e.target))){
			// 	// 	  return;
			// 	//   }else{					 	
			// 	// 	  _this.wechatShow = false;
			// 	//   }
			// })
			// document.addEventListener('click', function (e) {
			// 	if (e.target.className !== 'weibo') {
			// 		_this.weiboShow = false;
			// 	}
			// 	//   if((_this.$refs.weiboModal&&!!_this.$refs.weiboModal.contains(e.target)) ||(_this.$refs.weibo&&!!_this.$refs.weibo.contains(e.target)) ){
			// 	// 	  return;
			// 	//   }else{					 	
			// 	// 	  _this.weiboShow = false;
			// 	//   }
			// })
			// document.addEventListener('click', function (e) {
			// 	if (e.target.className !== 'wechat') {
			// 		_this.show = false;
			// 	}
			// 	//   if((_this.$refs.wechatModal&&!!_this.$refs.wechatModal.contains(e.target)) || (_this.$refs.wechat&&!!_this.$refs.wechat.contains(e.target))){
			// 	// 	  return;
			// 	//   }else{					 	
			// 	// 	  _this.show = false;
			// 	//   }
			// })
		},
		updated(){
			let wechat = this.$refs.wechat.getBoundingClientRect();
			let weibo = this.$refs.weibo.getBoundingClientRect();
			console.log(wechat)
			console.log(weibo)		
			this.weiboRight = (window.innerWidth - weibo.right) + 'px';
			this.wechatRight = (window.innerWidth - wechat.right) + 'px';
			this.weiboBottom = (window.innerHeight - weibo.bottom + 50 ) + 'px';
		}
	}
</script>

<style lang="less" scoped>
	.footer {
		position: relative;
		bottom: 0;
		width: 1200px;
		color: #565656;
		background: #fff;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.nav {
			padding: 20px;
			z-index: 999;

			.contact {
				font-weight: bold;
				display: inline-block;
				margin: 0 10px;
				color: #565656;
			}

			a {
				font-weight: bold;
				display: inline-block;
				margin: 0 10px;
				color: #565656;
			}

			.router-link-exact-active {
				color: #565656;
			}
		}

		.logo {
			padding-left: 40px;
			padding-right: 5px;
			font-weight: bold;
			position: relative;

			img {
				margin-left: 5px;
			}

			.code {
				width: 36px;
			}


		}


		.cs-mask {
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
          
			/* background: rgba(0,0,0,0.1); */
			.cs-modal {
				position: absolute;
				width: 1200px;
				left: 50%;
				margin-left: -600px;
				
				z-index: 99;
				background: #fff;
				border: 1px #f0f0f0 solid;
				padding: 20px 100px;
				display: flex;
			}

			.big-code {
				position: absolute;			
				width: 180px;
				border: 1px solid #eaeaea;
			}

			.big-weibo-code {
				position: absolute;			
				width: 180px;
				border: 1px solid #eaeaea;
			}
		}

	}



	.modal-left {
		width: 50%;
		padding-top: 10px;
		border-right: 1px #f0f0f0 solid;
		display: flex;

		.tel-img {
			width: 60px;
			height: 60px;
		}

		.tel-cont {
			margin-left: 20px;

			.tel-title {
				margin-bottom: 10px;
			}

			.tel {
				font-size: 20px;
				color: #bca57d;
			}
		}
	}

	.modal-right {
		width: 50%;
		padding-left: 100px;
		display: flex;

		.code-box {
			border: 1px #f0f0f0 solid;

			img {
				width: 80px;
				height: 80px;
			}
		}

		.wechat-service {
			margin-left: 20px;

			.wechat-title {
				padding-top: 8px;
			}

			.info {
				margin-top: 10px;
				font-size: 12px;
			}
		}

	}

	@media screen and (max-width:1280px) {
		.footer {
			width: 100%;

			.cs-mask {
				.cs-modal {
					position: absolute;
					width: 100%;
				    margin-left: -50%;
					padding:20px;
				}
			}
		}


	}

	@media screen and (max-width:1120px) {
		.footer {
			.nav {
				padding: 20px 0 20px 5px;

				.contact {
					margin: 0 2px;
				}

				a {
					margin: 0 2px;
				}
			}

			.logo {
				padding-left: 5px;
			}

		}
	}

	@media screen and (max-width:1020px) {
		.footer {
			.copyright {
				font-size: 12px;
			}

		}
	}
</style>