import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import router from './router'
import Antd from 'ant-design-vue';
import waterfall from 'vue-waterfall2'

import 'ant-design-vue/dist/antd.css'
import './global.less'
import 'vue2-animate/dist/vue2-animate.min.css'
import * as net from '@/api/net'
import { message} from 'ant-design-vue'


Vue.use(VueRouter)
Vue.use(waterfall)
Vue.use(Antd);

Vue.prototype.$net = net;
Vue.prototype.$message = message;
Vue.config.productionTip = false;
//CNZZ_trackEvent统计
// Vue.prototype.$cnzz_Event = (action,label) => {
//   if (window._czc) {
//       window._czc.push(["_trackEvent", action, label]);
//       console.log(window._czc);
//   }
// }

message.config({ 
  top:`400px`, // 到页面顶部距离
  duration: 3,
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
