<template>
	<div class="img-list">
		<waterfall :col="4" :data="list" @loadmore="loadMore">
			<template>
				<div @click="goDetail(item)" class="img-item" v-for="(item,url600) in list" :key="url600">
					<div class="image">
						<img :src="item.url600" alt="加载错误" referrerpolicy="no-referrer" />
						<div class="action viewGroup">
							<img src="@/assets/action/viewGroup.png">
						</div>
						<!-- <div class="action similar">
							<img src="@/assets/action/similar.png">
						</div> -->
						<div class="action info">
							<p v-if="item.detail&&item.detail.user">｜{{item.detail.user||''}}</p>
							<p v-if="item.detail&&item.detail.address">｜{{item.detail.address||''}}</p>
							<p v-if="item.detail&&item.detail.people">｜{{item.detail.people||''}}</p>
							<p v-if="item.detail&&item.detail.home">｜{{item.detail.home||''}}</p>
							<p v-if="item.detail&&item.detail.goods">｜{{item.detail.goods||''}}</p>
						</div>
					</div>
					<p class="imgTitle">{{item.category}}</p>
				</div>
			</template>
		</waterfall>
		<div style="width: 100%;text-align: center;font-size: 18px;color: #999;margin-top: 30px;" v-if="list.length==0">
			<span v-if="initReady">没有找到您要的照片~</span>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'DwImg',
		props: {
			imgList: {
				type: Array,
				default: []
			},
			initReady: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				showFilter: false,
				filterOpen: false,
				footerText:'',
				list:[]
			}
		},
		computed: {

		},
		mounted () {
			
		},
		watch: {
			imgList: {
				handler(newVal, oldVal) {
					this.list = newVal
				},
				immediate: true,

			}
		},
		methods: {
			goDetail(item) {
				console.log(item)
				this.$router.push({
					path: '/project-detail',
					query: {
						category: item.category,
						firstUrl:item.url
					}
				})
			},
			loadMore() {
				console.log('loadMore')
			}
		}
	}
</script>

<style lang="less" scoped>
	.img-list {
		height: 100%;
		padding: 0 20px;
		overflow: hidden;
		min-height:calc( 100vh - 530px );
		.img-item {
			padding: 5px;
			cursor: pointer;

			.image {
				position: relative;

				.action {
					display: none;
					position: absolute;
				}

				.viewGroup {
					left: 5px;
					bottom: 5px;
				}

				.similar {
					right: 5px;
					top: 5px;
				}

				.info {
					right: 0;
					bottom: 0;
					width: 190px;
					background: rgba(0, 0, 0, .6);
					color: #fff;
					padding: 10px 5px 0 10px;
					border-top-left-radius: 15px;

					p {
						font-size: 12px;
						margin-bottom: 5px;
					}
				}

				&:hover {
					.action {
						display: block;
					}

				}

				;
			}
		}

		img {
			width: 100%;
		}

		.imgTitle {
			margin-top: 10px;
			margin-bottom: 0;
			text-align: center;
		}
	}
</style>