import DwFilter from '@/components/DwFilter.vue'
import DwImg from '@/components/DwImg.vue'
export default {
    name: 'list',
    components: {
        DwFilter,
        DwImg
    },
    data() {
        return {
            active: '',
            imgList: [],
            searchInput: null,
            classifyObj: {
                living: '智慧客厅',
                kitchen: '智慧厨房',
                living: '智慧阳台',
                bedroom: '智慧卧室',
                bathroom: '智慧卫浴',

            },
            classifyTag: [],
            commonTag: [], 
            orCommonTag: [], 
            specialTag: [],
            orSpecialTag: [],
            defaultTag: ['智慧客厅', '智慧厨房', '智慧阳台', '智慧卧室', '智慧卫浴'],
            body: {
                categoryAggs: 200
            },
            pageSize:50,
            pageNumber:1,
            historyList:[],
            loading:false,
            initReady:false
        }
    },
    created() {
        this.classifyTag = this.defaultTag;
    },
    mounted() {
        window.addEventListener('scroll', ()=>{
            var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
            var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
            var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
            if(scrollTop+windowHeight == scrollHeight){
                if(!this.loading){
                    this.pageNumber++;
                    this.getImage(true);
                }
            } 
        });
        if (this.$route.query.type) {
            this.active = this.$route.query.type;
        }
        this.getImage();
    },
    methods: {
        loadMore(ev) {
        },
        filterChange(ev) {
            this.initReady = false;
            this.commonTag = ev.commonTag;
            this.orCommonTag = ev.orCommonTag;
            this.specialTag = ev.specialTag;
            this.orSpecialTag = ev.orSpecialTag;
            this.searchInput = ev.searchInput;
            this.imgList = [];
            this.pageNumber = 1;
            this.getImage();
        },
        getImage(loadmore=false) {
            this.loading = true;
            let size = [600, 1800, 2400];          
            let commonTag = this.commonTag&&this.commonTag.length?[...this.commonTag, ...this.classifyTag]:this.classifyTag;     
            let array = Array.from(new Set(commonTag));//去重
            const orCommonTag = this.orCommonTag?JSON.parse(JSON.stringify(this.orCommonTag)):[]
            if(array.includes('智慧厨房')){
                orCommonTag.push('智慧中式厨房')
                orCommonTag.push('智慧西式厨房')
            }
            this.body['searchWord'] = this.searchInput && this.searchInput !== '' ? this.searchInput : null;
            this.body['commonTag'] = array.length ? array.join().replace('智慧厨房','') : null;
            this.body['specialTag'] = this.specialTag && this.specialTag.length ? this.specialTag.join() : null;


            this.body['orCommon'] = orCommonTag && orCommonTag.length ? orCommonTag.join().replace('智慧厨房','智慧中式厨房,智慧中式厨房') : null;
            this.body['orSpecial'] = this.orSpecialTag && this.orSpecialTag.length ? this.orSpecialTag.join() : null;
            // this.body['pageSize'] = this.pageSize;
            // this.body['pageNumber'] = this.pageNumber;
            this.$net.getImg(this.body)
                .then(res => {                  
                    if (res.page.content.length) {
                        let arr = res.page.content;
                        let aggs = [];
                        if(loadmore){
                            aggs = res.page.aggregations.categoryAggs.splice(parseInt((this.pageNumber-1)*this.pageSize),this.pageSize);
                        }else{
                            aggs = res.page.aggregations.categoryAggs.splice(0,this.pageSize);
                        }
                        let data = [];
                        const list = [];
                        if (aggs && aggs.length) {
                            aggs.map(v => {
                                for (let w of arr) {
                                    if (v['term'] !== '首页轮播图' && w['category'] == v['term']) {
                                        size.map(u => {
                                            let pre = w['url'].split('.' + w['ext'])[0];
                                            w['url' + u] = pre + '_' + u + '.' + w['ext'];
                                        })
                                        list.push(w);
                                        break;
                                    }
                                }
                            })
                        }
                        this.imgList = [...this.imgList,...list];
                        if (this.imgList.length) {
                            this.getDescription();
                        }
                    }
                    this.loading = false;
                    this.initReady = true;
                })
        },

        getDescription() {
            this.imgList.map(item => {
                item['detail'] = {};
                let arr = item.tag.split(',');
                arr.map(v => {
                    if (v.indexOf('编号') !== -1) {
                        item['detail']['no'] = v;
                    } else if (v.indexOf('用户') !== -1) {
                        item['detail']['user'] = v;
                    } else if (v.indexOf('省市') !== -1) {
                        //地点后面展示省市
                       
                        item['detail']['address'] = '地点' + '：' + v.split('：')[1];
                    } else if (v.indexOf('家庭') !== -1 && v.indexOf('家庭人口') == -1) {
                        item['detail']['people'] = v;
                    } else if (v.indexOf('住所') !== -1) {
                        item['detail']['home'] = v;
                    } else if (v.indexOf('产品') !== -1) {
                        item['detail']['goods'] = v;
                    }
                })

            })
        },

        setActive(type) {
            this.initReady = false;
            this.active = type;
            this.classifyTag = [];
            if (this.active) {
                this.classifyTag = [this.classifyObj[type]];
            }
            if (!this.active) {
                this.classifyTag = this.defaultTag;
            }
            this.pageNumber = 1;
            this.imgList = [];
            this.getImage();
        },
    }
}