<template>
  <div id="app" v-if="initReady">
    <dw-header></dw-header>
    <router-view />
    <div style="clear: both;"></div>
    <dw-footer></dw-footer>
  </div>
</template>
<script>
  import DwHeader from '@/components/DwHeader.vue'
  import DwFooter from '@/components/DwFooter.vue'
  export default {
    components: { DwHeader, DwFooter },
    data() {
      return {
        initReady: false
      }
    },
    watch: {
      //友盟统计
      $route: {
        handler(newVal, oldVal) {  
          setTimeout(() => {        
            if (window._czc) {
              let location = window.location;             
              let contentUrl = location.pathname + location.hash;// 为需要统计PV的页面
              let refererUrl = location.href;// 该受访页面的来源页URL地址
              console.log(contentUrl, refererUrl)
              window._czc.push(["_trackPageview", contentUrl, refererUrl]);
              console.log(window)
            }  
          }, 300);          
        },
        immediate: true,
        deep: true
      }

    },
    created() {
      if (window.screen.width < 768) {
        console.log(this.$route)

        let label = document.createElement('a');
        if (this.$route.path == '/') {
          label.href = 'http://www.casartephoto.cn/casarteh5/#/tabs/tab1';
        } else if (this.$route.path == '/product') {
          label.href = 'http://www.casartephoto.cn/casarteh5/#/tabs/tab4?classify=fresh';
        } else if (this.$route.path == '/project-list') {
          label.href = 'http://www.casartephoto.cn/casarteh5/#/tabs/tab2?classify=living';
        } else if (this.$route.path == '/life') {
          label.href = 'http://www.casartephoto.cn/casarteh5/#/tabs/tab5?classify=0';
        } else if (this.$route.path == '/ques') {
          label.href = 'http://www.casartephoto.cn/casarteh5/#/feedback/index';
        } else if (this.$route.path == '/project-detail') {
          label.href = `http://www.casartephoto.cn/casarteh5/#/settle/details?category=${this.$route.query.category}&firstUrl=${this.$route.query.firstUrl}`;
        } else if (this.$route.path == '/whole-house') {
          label.href = `http://www.casartephoto.cn/casarteh5/#/tabs/tab3?classify=heat`;
        } else {
          label.href = 'http://www.casartephoto.cn/casarteh5/#/tabs/tab1';
        }

        label.click();
      }
    },
    mounted() {
      setTimeout(() => {
        this.initReady = true;
      }, 300)
      // 友盟统计添加
      // const script = document.createElement("script");
      // script.src =
      //   "https://s4.cnzz.com/z_stat.php?id=1281045619&web_id=1281045619";   //该路径为点击统计代码后，页面中第一个 文字统计 script代码
      // script.language = "JavaScript";
      // document.body.appendChild(script);
    }
  }
</script>
<style lang="less" scoped>
  #app {
    font-family: "Microsoft Yahei";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    min-height: 100vh;
  }
</style>