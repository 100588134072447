import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ProjectDetail from '../views/ProjectDetail.vue'
import ProjectList from '../views/ProjectList.vue'
import Product from '../views/Product.vue'
import Project from '../views/Project.vue'
import Life from '../views/Life.vue'
import WholeHouse from '../views/WholeHouse.vue'
import Ques from '../views/Ques.vue'
import List from '../views/List.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/project',
    name: 'Project',
    // component: () => import('../views/Project.vue')
    component: Project
  },
  {
    path: '/project-list',
    name: 'ProjectList',
    component: ProjectList
  },
  {
    path: '/project-detail',
    name: 'ProjectDetail',
    component: ProjectDetail
    // component: () => import('../views/ProjectDetail.vue')
  },
  {
    path: '/product',
    name: 'Product',
    // component: () => import('../views/Product.vue')
    component: Product
  },
  {
    path: '/life',
    name: 'Life',
    // component: () => import('../views/Life.vue')
    component: Life
  },
  {
    path: '/whole-house',
    name: 'WholeHouse',
    // component: () => import('../views/Life.vue')
    component: WholeHouse
  },
  {
    path: '/ques',
    name: 'Ques',
    // component: () => import('../views/Ques.vue')
    component: Ques
  },
  {
    path: '/list',
    name: 'List',
    component: List
  },
  {
    path: '/law',
    name: 'Law',
    component: () => import('../views/Law.vue')
  },
  {
    path: '/service',
    name: 'Service',
    component: () => import('../views/Service.vue')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
      return {x: 0, y: 0};
  }
})

export default router
