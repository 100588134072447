<template>
	<div class="home">
		<a-carousel :after-change="onChange" autoplay>
			<div class="dw-img" v-for="(item,i) in slides" :key="i">
				<img :src="item.url" referrerpolicy="no-referrer">
			</div>
		</a-carousel>
		<div class="main">
			<div class="content">
				<!-- <div>
	  			<img class="cLogo" src="@/assets/clogo.png">
	  		</div>
	  		<div>
	  			<img class="title" src="@/assets/title.png">
	  		</div> -->
				<!-- 	  		<h5>卡萨帝用户照片数据库</h5> -->

				<dw-filter @change="filterChange"></dw-filter>

			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
    import md5 from 'md5'
	import DwFilter from '@/components/DwFilter.vue'
	export default {
		name: 'Home',
		components: {
			DwFilter
		},
		data() {
			return {
				slides: []
			}
		},
		mounted() {

			this.$net.homeSlide()
				.then(res => {
					console.log(res)
					this.slides = res.page.content;
				})
			// const url = "http://www.casartephoto.cn/visitCount.json";
			// let request = this.getXmlHttpObject();
			// request.open("get", url);/*设置请求方法与路径*/

			// request.send(null);/*不发送数据到服务器*/

			// request.onload = () => {/*XHR对象获取到返回信息后执行*/

			// 	if (request.status == 200) {/*返回状态为200，即为数据获取成功*/

			// 		let visitNum = JSON.parse(request.responseText).visitNum;
			// 		console.log(visitNum);
			// 		request.open("post", url);
			// 		request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');		
			// 		request.send({visitNum:visitNum++})			

			// 	}
			// }
			const now = new Date().getTime();
			const str = this.createNonceStr();

			let params = {
				t: now,
				n: str,
				s: md5(now + str + "C&asart1_+Dw!")
			}
			this.$net.addPv(params).then(res => {

			})



		},
		methods: {
			createNonceStr() {
				let chars = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
				let nums = "";
				for (let i = 0; i < 20; i++) {//这里是几位就要在这里不改变
					let id = parseInt(Math.random() * 26);
					nums += chars[id];
				}
				return nums;
			},

			getXmlHttpObject() {
				var xmlHttp = null;

				try {
					// Firefox, Opera 8.0+, Safari
					xmlHttp = new XMLHttpRequest();
				}
				catch (e) {
					// Internet Explorer
					try {
						xmlHttp = new ActiveXObject("Msxml2.XMLHTTP");
					}
					catch (e) {
						xmlHttp = new ActiveXObject("Microsoft.XMLHTTP");
					}
				}
				return xmlHttp;
			},
			filterChange() {
				this.$router.push({
					path: '/list'
				})
			},
			onChange(ev) {

			}
		}
	}
</script>
<style lang="less" scoped type="text/css">
	.ant-input {
		height: 50px;
	}

	.home {
		position: relative;

		.dw-img {
			width: 100%;

			img {
				width: 100%;
			}
		}

		.main {
			margin: 0 auto;
			padding: 90px 10px;
			position: absolute;
			width: 100%;
			left: 0;
			top: 0;

			.content {
				width: 1200px;
				margin: 0 auto;
				padding-top: 235px;

				.cLogo {
					display: block;
					margin: 0 auto;
					animation: dropdown 1.5s linear 1;

					&:hover {
						animation: dropdown 1.5s linear 1;
					}
				}

				.title {
					display: block;
					margin: 35px auto;
				}

				h5 {
					width: 100%;
					text-align: center;
					margin-top: 50px;
					font-size: 50px;
					color: #fff;
					font-weight: normal;
					letter-spacing: 10px;
				}

				@keyframes dropdown {
					0% {
						transform: scale(1)
					}

					50% {
						transform: scale(1);
						filter: brightness(1.2);
					}

					100% {
						transform: scale(1)
					}
				}
			}

			@media screen and (max-width:1280px) {
				.content {
					width: 100%;
				}
			}

			@media screen and (max-width:1024px) {
				.content {
					padding-top: 100px;
				}
			}

		}
	}
</style>