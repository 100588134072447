import axios from 'axios'
import { JSEncrypt } from 'jsencrypt';

const service = axios.create({
  baseURL: 'http://www.casartephoto.cn',//'http://open.haier.com'
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000 
})

const athenaAppName = '卡萨帝图片应用第三方';
const athenaAppKey = '4d9466f1d9d9406694a335b31a389378';
const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCZLqvfXBMqKRV1LgiM3PBkiK7Ix4P91Zw+tKhsEtK/QVuafBeyXKO67f50mEtSK6xoesNomcc5KGG/ndhu3dTv8KG33/joW8pbvOMnNAchPUiCb34su5Ph72K6Fs1a1EGmtXIb7hjQFxwoWws7SVOszTQXqFVnEwY8KsX3GTlW+QIDAQAB';
const rsa = {
  encrypt: (publicKey, data) => {
    var encrypt = new JSEncrypt();
    encrypt.setPublicKey('-----BEGIN PUBLIC KEY-----' + publicKey + '-----END PUBLIC KEY-----');
    var encrypted = encrypt.encrypt(data);
    return encrypted;
  }
};

service.interceptors.request.use(
 
  config => {
    console.log(config.url.indexOf('C901A553D347665821930D35C3B4B32640CFDACB2DD0BE1D3AB1A05D28DC9208B71BB599ECF55A816CF4117641DE4644')==-1?
    'application/x-www-form-urlencoded;charset=UTF-8':
    'application/json');
    config.headers['athenaAppKey'] = encodeURIComponent(rsa.encrypt(publicKey, athenaAppKey));
    config.headers['athenaAppName'] = encodeURIComponent(athenaAppName);
    config.headers['Content-Type'] = config.url.indexOf('C901A553D347665821930D35C3B4B32640CFDACB2DD0BE1D3AB1A05D28DC9208B71BB599ECF55A816CF4117641DE4644')==-1?
    'application/x-www-form-urlencoded;charset=UTF-8':
    'application/json'; 
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    if (response.status == 200) {
      return response.data
    } else{
      return Promise.reject('网络错误')
    }
  },
  error => {
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

export default service
