<template>
	<div class="project">
		<div class="main">
			<div class="content">
				<dw-filter :mode="'light'" @change="filterChange"></dw-filter>
				<div class="cate-list">
					<div class="img img1" :class="active=='fresh'?'active':''" @click="setActive('fresh')">
						<img v-if="active=='fresh'" src="@/assets/cate1/cate4-a.png">
						<img v-else src="@/assets/cate1/cate4.png">
						<p>全屋新鲜</p>
					</div>
					<div class="img img1" :class="active=='wash'?'active':''" @click="setActive('wash')">
						<img v-if="active=='wash'" src="@/assets/cate1/cate3-a.png">
						<img v-else src="@/assets/cate1/cate3.png">
						<p>全屋洗护</p>
					</div>					
					<div class="img img1" :class="active=='air'?'active':''" @click="setActive('air')">
						<img v-if="active=='air'" src="@/assets/cate1/cate1-a.png">
						<img v-else src="@/assets/cate1/cate1.png">
						<p>全屋空气</p>
					</div>						
					<div class="img img1" :class="active=='water'?'active':''" @click="setActive('water')">
						<img v-if="active=='water'" src="@/assets/cate1/cate5-a.png">
						<img v-else src="@/assets/cate1/cate5.png">
						<p>全屋用水</p>
					</div>
					<div class="img img1" :class="active=='heat'?'active':''" @click="setActive('heat')">
						<img v-if="active=='heat'" src="@/assets/cate1/cate2-a.png">
						<img v-else src="@/assets/cate1/cate2.png">
						<p>全屋采暖</p>
					</div>
					<div class="img" :class="active=='smart'?'active':''" @click="setActive('smart')">
						<img v-if="active=='smart'" src="@/assets/cate1/cate6-a.png">
						<img v-else src="@/assets/cate1/cate6.png">
						<p>全屋智能</p>
					</div>
				</div>
				<dw-img :initReady="initReady" :imgList="imgList"></dw-img>
			</div>


		</div>
		<a-back-top />
	</div>
</template>

<script>
	// @ is an alias to /src
	import DwFilter from '@/components/DwFilter.vue'
	import DwImg from '@/components/DwImg.vue'
	import ListMixin from '@/mixin/list.js'
	export default {
		name: 'WholeHouse',
		mixins:[ListMixin],
		components: {
			DwFilter,
			DwImg
		},
		data() {
			return {
				active: 'fresh',
				imgList: [],
				searchInput: null,
				classifyObj: {
					heat: '全屋采暖',
					air: '全屋空气',
					wash: '全屋洗护',
					fresh: '全屋新鲜',
					water: '全屋用水',
					smart: '全屋智能',
					
				},
				defaultTag: ['全屋新鲜'],
				commonTag:[],
				body: {
					categoryAggs: 200
				}
			}
		},
		
		methods: {

			loadMore(ev) {
				console.log(ev)
			},
			
		}
	}
</script>
<style lang="less" scoped type="text/css">
	.project {
		position: relative;
		padding-top: 60px;
		.main {
			/* min-height: 100vh; */
			background: #f5f5f5;
			padding: 20px 0 10px 0;
			margin: 0 auto;
			width: 100%;
			left: 0;
			top: 0;

			.content {
				width: 1200px;
				margin: 0 auto;
			}

			.cate-list {
				width: 1100px;
				margin: 20px auto 20px auto;
				display: flex;

				.img {
					overflow: hidden;
					margin-bottom: 10px;
					width: 100%;
					text-align: center;
					background-color: #fff;
					padding: 50px 0 40px 0;

					img {
						transition: all 0.6s;
						cursor: pointer;

						&:hover {
							transform: scale(1.1);
						}
					}

					p {
						margin-top: 20px;
						margin-bottom: 0px;
						text-align: center;
						color: #565656;
						font-size: 15px;
					}
				}

				.active {
					background-color: #5C2F7E;

					p {
						color: #fff;
					}
				}

				.img1 {
					border-right: 1px solid #eaeaea;
				}



			}
			@media screen and (max-width:1280px) {
				.cate-list {
					width: 90%;
				}
			}
			@media screen and (max-width:1280px) {
				.cate-list {
					width: 90%;
				}
				
			}
		}
	}
</style>
<style lang="less" scoped type="text/css">
	@import url('@/mixin/base.less');
</style>