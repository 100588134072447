<template>
	<div class="dwFilter" :class="mode">
		<div :class="{'input-bg':true,'bg-dark':mode=='dark'}"
			style="min-height: 58px;z-index: 999999;animation-duration: 0.5s;display: flex;align-items: center;box-sizing: border-box;">
			<a-icon :class="{'arrow':true,'arrow-active':showFilter}" style="flex-shrink: 0;"
				@click="showFilter=!showFilter" :type="'caret-down'" />
			<div style="display: flex;width: 97%;align-items: center;">
				<div class="tags" :style="{color:mode=='dark'?'#fff':'#333'}">
					<a-tag v-for="(item,label) in tagList" :key="label" :closable="item.label!=='...'"
						@close="preventDefault($event,item)">
						<span v-if="item.label=='...'" :title="hideTags">{{item.label}}</span>
						<span v-else>{{item.label}}</span>
					</a-tag>
				</div>
				<a-input class="dwInput" :class="showFilter?'input-active':''" v-model="searchInput"
					:placeholder="searchPlaceholder" @keyup.enter="search">
					<img slot="suffix" src="@/assets/action/search.png" @click="search">
				</a-input>

			</div>
		</div>
		<transition enter-active-class="fadeInDown" leave-active-class="fadeOutUp">
			<div class="filter" v-if="showFilter" style="animation-duration: 0.5s">
				<a-select ref="age" class="filterItem" :class="form.age?'dwSelect':''" v-model="form.age"
					placeholder="年龄阶段" @change="(value,option)=>changeOption(value,option,'age')" allowClear>
					<a-icon slot="suffixIcon" :component="SelectSvg" />
					<a-select-option v-for="(item,i) in age" :key="i" :value="item.value">
						{{item.label}}
					</a-select-option>
				</a-select>
				<a-select class="filterItem" :class="form.people?'dwSelect':''" v-model="form.people" placeholder="家庭人口"
					@change="(value,option)=>changeOption(value,option,'people')" allowClear>
					<a-icon slot="suffixIcon" :component="SelectSvg" />
					<a-select-option v-for="(item,i) in people" :key="i" :value="item.value">
						{{item.label}}
					</a-select-option>
				</a-select>
				<a-select class="filterItem" :class="form.family?'dwSelect':''" v-model="form.family" placeholder="家庭成员"
					@change="(value,option)=>changeOption(value,option,'family')" allowClear>
					<a-icon slot="suffixIcon" :component="SelectSvg" />
					<a-select-option v-for="(item,i) in family" :key="i" :value="item.value">
						{{item.label}}
					</a-select-option>
				</a-select>
				<a-cascader class="filterItem" expand-trigger="hover" :class="form.estate?'dwSelect':''"
					v-model="form.estate" :options="estate" placeholder="房屋类型"
					@change="(value,selectedOptions)=>changeCascader(value,selectedOptions,'estate')">
					<a-icon slot="suffixIcon" :component="SelectSvg" />
				</a-cascader>
				<a-cascader class="filterItem" expand-trigger="hover" :class="form.decoration?'dwSelect':''"
					v-model="form.decoration" :options="decoration" placeholder="装修风格"
					@change="(value,selectedOptions)=>changeCascader(value,selectedOptions,'decoration')"">
			    	<a-icon slot="suffixIcon" :component="SelectSvg" />
				</a-cascader>
				<a-cascader class="filterItem" expand-trigger="hover" :class="form.product?'dwSelect':''"
					v-model="form.product" :options="product" placeholder="高端产品"
					:popupStyle="{ maxHeight: '300px', overflowY: 'auto' }"
					@change="(value,selectedOptions)=>changeCascader(value,selectedOptions,'product')"">
			    	<a-icon slot="suffixIcon" :component="SelectSvg" />
				</a-cascader>
				<a-select class="filterItem" :class="form.schema?'dwSelect':''" v-model="form.schema" placeholder="全屋方案"
					@change="(value,option)=>changeOption(value,option,'schema')" allowClear>
					<a-icon slot="suffixIcon" :component="SelectSvg" />
					<a-select-option v-for="(item,i) in schema" :key="i" :value="item.value">
						{{item.label}}
					</a-select-option>
				</a-select>
				<a-select class="filterItem" :class="form.space?'dwSelect':''" v-model="form.space" placeholder="智慧空间"
					@change="(value,option)=>changeOption(value,option,'space')" allowClear>
					<a-icon slot="suffixIcon" :component="SelectSvg" />
					<a-select-option v-for="(item,i) in space" :key="i" :value="item.value">
						{{item.label}}
					</a-select-option>
				</a-select>
				<a-cascader class="filterItem" expand-trigger="hover" :class="form.style?'dwSelect':''"
					v-model="form.style" :options="style" placeholder="格调生活"
					:popupStyle="{ maxHeight: '300px', overflowY: 'auto' }"
					@change="(value,selectedOptions)=>changeCascader(value,selectedOptions,'style')"">
			    	<a-icon slot="suffixIcon" :component="SelectSvg" />
				</a-cascader>

			</div>
		</transition>

	</div>
</template>
<script>

	const SelectSvg = {
		template: `
    <svg id="图层_1" data-name="图层 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.08 6"><path class="cls-1" d="M10,7.44,4.3,3a.74.74,0,0,1-.19-1,.93.93,0,0,1,1.06-.35l5.08,1.58a1,1,0,0,0,.57,0L15.9,1.68A.93.93,0,0,1,17,2a.74.74,0,0,1-.19,1l-5.65,4.4a1,1,0,0,1-1.15,0" transform="translate(-3.99 -1.64)"/></svg>
  `,
	};
	export default {
		name: 'DwFilter',
		components: {
		},
		props: {
			
			mode: {
				type: String,
				default: 'dark'
			},
			inputData: {
				type: String,
				default: ''
			},
			formData: {
				type: Array,
				default: function () {
					return []
				}
			}
		},
		watch: {
			mode: {
				handler(newVal, oldVal) {
					let classList = document.getElementsByTagName('body')[0].classList;
					classList.remove('light')
					classList.remove('dark')
					classList.add(newVal);
				},
				immediate: true
			},
			formData: {
				handler(newVal, oldVal) {
					console.log(newVal);
					if (newVal) {
						this.tagList = newVal;
						this.setTag();
					}

				},
				immediate: true
			},
			inputData: {
				handler(newVal, oldVal) {
					console.log(newVal);
					this.searchInput = newVal ? newVal : '';

				},
				immediate: true
			}
		},
		data() {
			return {
				SelectSvg: SelectSvg,
				showFilter: true,
				filterOpen: false,
				searchInput: null,
				form: {},
				age: [
					{ label: '单身贵族（25以下）', value: '1' },
					{ label: '年轻翘楚（25-35）', value: '2' },
					{ label: '时尚新贵（35-40）', value: '3' },
					{ label: '成功新锐（40-45）', value: '4' },
					{ label: '时代精英（40-50）', value: '5' },
					{ label: '颐养天年（50以上）', value: '6' }
				],
				people: [
					{ label: '1', value: '1', specialLabel: '家庭人口：1' },
					{ label: '2', value: '2', specialLabel: '家庭人口：2' },
					{ label: '3', value: '3', specialLabel: '家庭人口：3' },
					{ label: '4', value: '4', specialLabel: '家庭人口：4' },
					{ label: '5以上', value: '5', specialLabel: '家庭人口：5以上' }
				],
				family: [
					{ label: '单身贵族', value: '1' },
					{ label: '夫妻双方', value: '2' },
					{ label: '有儿有女', value: '3' },
					{ label: '三代同堂', value: '4' },
					{ label: '家有萌宠', value: '5' }
				],
				estate: [
					{ label: '复式', value: '1' },
					{ label: '大平层', value: '2' },
					{ label: '院子', value: '3' },
					{ label: '私人会所', value: '4' },
					{ label: '别墅', value: '5', children: [{ label: '独栋', value: '1', specialLabel: '别墅（独栋）' }, { label: '联排', value: '2', specialLabel: '别墅（联排）' }, { label: '双拼', value: '3', specialLabel: '别墅（双拼）' }, { label: '叠拼', value: '4', specialLabel: '别墅（叠拼）' }] }
				],
				decoration: [
					{ label: '美式', value: '1' },
					{ label: '法式', value: '2' },
					{ label: '中式', value: '3', children: [{ label: '传统', value: '1', specialLabel: '中式（传统）' }, { label: '新中式', value: '2', specialLabel: '中式（新中式）' }] },
					{ label: '欧式', value: '4', children: [{ label: '古典', value: '1', specialLabel: '欧式（古典）' }, { label: '简欧', value: '2', specialLabel: '欧式（简欧）' }] },
					{ label: '现代', value: '5', children: [{ label: '简约', value: '1', specialLabel: '现代（简约）' }, { label: '轻奢', value: '2', specialLabel: '现代（轻奢）' }] }
				],
				product: [
					{
						label: '套系产品', value: '1',
						children: [{ label: '指挥家套系', value: '1' }, { label: '鉴赏家套系', value: '2' }, { label: '设计师套系', value: '3' }, { label: '银河套系', value: '4' }]
					},
					{
						label: '价格区间', value: '2',
						children: [{ label: '10000元以下', value: '1', specialLabel: '价格区间：10000元以下' }, { label: '10000-15000', value: '2', specialLabel: '价格区间：10000-15000' }, { label: '15000-20000', value: '3', specialLabel: '价格区间：15000-20000' }, { label: '20000-25000', value: '4', specialLabel: '价格区间：20000-25000' }, { label: '25000以上', value: '5', specialLabel: '价格区间：25000以上' }]
					},
					{
						label: '品类', value: '3',
						children: [{ label: '冰箱', value: '1' }, { label: '冰吧', value: '2' }, { label: '酒柜', value: '3' }, { label: '冷柜', value: '4' }, { label: '洗衣机', value: '5' }, { label: '空调', value: '6' }, { label: '电视', value: '7' }, { label: '热水器', value: '8' }, { label: '洗碗机', value: '9' }, { label: '厨电', value: '10' }, { label: '净水', value: '11' }, { label: '小家电', value: '12' }]
					},
					{
						label: '颜色', value: '4',
						children: [{ label: '香槟金', value: '1' }, { label: '晶钻紫', value: '2' }, { label: '暮云黑', value: '3' }, { label: '太空银', value: '4' }, { label: '奢华白', value: '5' }, { label: '晶钻黛', value: '6' }, { label: '晶钻黛', value: '7' }, { label: '米兰灰', value: '8' },
						{ label: '银河蓝', value: '9' }, { label: '丝墨青', value: '10' }, { label: '浅金棕', value: '11' }, { label: '波尔多红', value: '12' }]
					}
				],
				schema: [
					{ label: '全屋新鲜', value: '1' },
					{ label: '全屋洗护', value: '2' },
					{ label: '全屋空气', value: '3' },
					{ label: '全屋用水', value: '4' },
					{ label: '全屋采暖', value: '5' },
					{ label: '全屋智能', value: '6' }
				],
				space: [
					{ label: '智慧客厅', value: '1' },
					{ label: '智慧厨房', value: '2' },
					{ label: '智慧阳台', value: '3' },
					{ label: '智慧卧室', value: '4' },
					{ label: '智慧卫浴', value: '5' },
					{ label: '智慧影音室', value: '6' },
				],
				style: [
					{
						label: '奢侈品', value: '1',
						children: [{ label: '服饰', value: '1' }, { label: '美妆', value: '2' }, { label: '珠宝', value: '3' }, { label: '腕表', value: '4' }, { label: '名车', value: '5' }]
					},
					{
						label: '休闲', value: '2',
						children: [{ label: '运动', value: '1' }, { label: '旅行', value: '2' }, { label: '音乐', value: '3' }, { label: '戏剧', value: '4' }, { label: '美食', value: '5' }]
					},
					{
						label: '艺术', value: '3',
						children: [{ label: '琴', value: '1' }, { label: '棋', value: '2' }, { label: '书', value: '3' }, { label: '画', value: '4' }, { label: '诗', value: '5' }, { label: '酒', value: '6' }, { label: '花', value: '7' }, { label: '茶', value: '8' }]
					}
				],
				commonTag: [],
				specialTag: [],
				tagList: [],
				tagData: [],
				hideList: [],
				hideTags: '',
				searchPlaceholder:'搜索您要找的图片'
       
			}
		},
		mounted(){
			
			this.$net.getPv().then(res => {
				console.log(res.data.data)
				// this.searchPlaceholder = `搜索您要找的照片，已有${res.data.data}人浏览`;
				this.searchPlaceholder = `搜索您要找的照片`;
			})
		},
		methods: {
			changeOption(value, option, type) {
				console.log(value, option, type);
				this.form[type] = undefined;
				if (value) {
					let label = this[type][value - 1]['specialLabel'] ? this[type][value - 1]['specialLabel'] : this[type][value - 1]['label'];
					let obj = this.tagList.find(v => { return v['label'] == label });
					let pid = type;
					if (!obj) {
						this.tagList.unshift({ label: label, type: type, pid: type, show: true })
					} else {

					}
				}

				this.setTag();
			},
			changeCascader(value, option, type) {
				console.log(value, option, type)
				this.form[type] = undefined;
				if (value.length) {
					let label = null;
					let pid = null;
					if (value.length == 2) {
						label = option[1]['specialLabel'] ? option[1]['specialLabel'] :
							option[1]['label'];
						pid = option[0]['label'];
					} else {
						label = option[0]['specialLabel'] ? option[0]['specialLabel'] :
							option[0]['label'];
						pid = type;
					}
					let obj = this.tagList.find(v => { return v['label'] == label });
					if (!obj) {
						this.tagList.unshift({ label: label, type: type, pid: pid, show: true })
					}


				}

				this.setTag();


			},
			setTag() {
				this.tagData = this.tagList.length ? this.tagList.slice(0, 6) : [];
				if (this.tagList.length > 6) {
					this.tagData.push({ label: '...' })
				}
				let hideTag = this.tagList.length ? this.tagList.slice(6) : [];
				this.hideTags = ''
				if (hideTag.length) {
					hideTag.map(v => {
						this.hideTags += this.hideTags == '' ? v['label'] : ',' + v['label']
					})
				}
			},
			preventDefault(e, ev) {
				e.preventDefault();
				let index = this.tagList.findIndex(v => { return v['label'] == ev['label'] });
				this.tagList.splice(index, 1)
				this.form[ev['type']] = undefined;

				this.setTag();
			},
			search() {
				//房屋类型和装修风格既有二级又有三级，不管是二级和三级之间还是三级和三级之间都是或的关系。格调生活这种只有三级，两个二级下面的三级之间是且的关系
				let orCommonTag = [];
				let orSpecialTag = [];
				this.commonTag = [];
				this.specialTag = [];
				console.log(this.tagList)
				this.tagList.map(v => {
					let sameTags = [];
					if (v.type == 'decoration' || v.type == 'estate') {
						sameTags = this.tagList.filter(w => { return w['type'] == v['type']});
					} else {
						sameTags = this.tagList.filter(w => { return w['pid'] == v['pid'] });						
					}
					if (sameTags.length > 1) {
							if (v['label'].indexOf('：') !== -1) {
								orSpecialTag.push(v['label']);
							} else {
								orCommonTag.push(v['label']);
							}
						} else {
							if (v['label'].indexOf('：') !== -1) {
								this.specialTag.push(v['label']);
							} else {
								this.commonTag.push(v['label']);
							}
						}

				})
			
				localStorage.setItem('filterForm', this.tagList.length ? JSON.stringify(this.tagList) : null);
				localStorage.setItem('commonTag', this.commonTag.length ? JSON.stringify(this.commonTag) : null);
				localStorage.setItem('specialTag', this.specialTag.length ? JSON.stringify(this.specialTag) : null);
				localStorage.setItem('orCommonTag', orCommonTag.length ? JSON.stringify(orCommonTag) : null);
				localStorage.setItem('orSpecialTag', orSpecialTag.length ? JSON.stringify(orSpecialTag) : null);
				localStorage.setItem('searchInput', this.searchInput);

				this.$emit('change', { 
					commonTag: this.commonTag, 
					orCommonTag:orCommonTag,
					specialTag: this.specialTag,
					orSpecialTag:orSpecialTag, 
					searchInput: this.searchInput })
			}
		}
	}
</script>

<style lang="less" scoped>
	.input-active {
		.arrow {
			transform: rotate(180deg);
		}
	}

	.input-bg {
		padding-left: 25px;
		border-radius: 10px;
		background: #e6e6e6 !important;
		color: #565656;
		border: 1px solid rgba(255, 255, 255, .3);
	}

	.bg-dark {
		background: rgba(0, 0, 0, 0.7) !important;
	}

	.arrow {
		color: #bdbebe;
		font-size: 30px;
		line-height: 30px;
		cursor: pointer;

	}

	.arrow-active {
		color: #bdbebe;
		font-size: 30px;
		line-height: 30px;
		cursor: pointer;
		transform: rotate(180deg);
	}

	.tags {
		max-width: 79%;
		flex-shrink: 0;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		margin-top: 0px;
		.ant-tag {
			padding: 3px 8px !important;
			margin: 0 2px 0px 0 !important;
			border-radius: 5px;
		}
	}

	.dwInput {
		min-width: 21%;
		border-radius: 10px;
		overflow: hidden;
		/* border: 1px solid rgba(255, 255, 255, .3); */

		/deep/ .ant-input {
			height: 58px;
			/* background: rgba(0, 0, 0, .7); */
			background: none;
			/* padding-left: 80px !important; */
			color: #fff;
			border: none;
			font-weight: 500;
		}

		/deep/ .ant-input-prefix {
			color: #BDBEBE;
			font-size: 30px;
			cursor: pointer;
			padding-left: 10px;
		}

		/deep/ .ant-input-suffix {
			color: #BDBEBE;
			font-size: 28px;
			cursor: pointer;
			padding-right: 10px;
		}
	}



	.dwFilter {
		width: 100%;
		padding: 0 30px;

		.filter {
			width: 97%;
			margin: 0 auto;
			background: rgba(0, 0, 0, 1);
			padding: 15px;
			display: flex;
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;

			.filterItem {
				margin: 2px;
				width: 10.74%;
				flex-shrink: 0;
			}


		}
	}

	.light {
		.dwInput {
			/deep/ .ant-input {
				background: #e6e6e6 !important;
				color: #565656;
			}
		}

		.filter {
			background: #66227E !important;
		}
	}
	@media screen and (max-width:1120px) {
		/deep/ .ant-select-selection__placeholder, .ant-select-search__field__placeholder{
			font-size: 12px;
		}
		/deep/ .ant-cascader-input.ant-input{
			font-size: 12px;
		}
 	}
</style>