<template>
  <div class="project">
  	<div class="main">
  		<div class="content">
	  		<dw-filter :mode="'light'"></dw-filter>
        <div class="cate-list">
          <div class="img" @click="goList('air')">
            <img src="@/assets/class/1.png">
          </div>
          <div class="img img1" @click="goList('fresh')">
            <img src="@/assets/class/2.png">
          </div>
          <div class="img img2" @click="goList('defend')">
            <img src="@/assets/class/3.png">
          </div>
          <div class="img img1" @click="goList('hot')">
            <img src="@/assets/class/4.png">
          </div>
          <div class="img img2" @click="goList('mind')">
            <img src="@/assets/class/5.png">
          </div>
        </div>
  		</div>
      
  	</div>
  </div>
</template>

<script>
// @ is an alias to /src
import DwFilter from '@/components/DwFilter.vue'
export default {
  name: 'Project',
  components: {
    DwFilter
  },
  data(){
  	return {
  		
  	}
  },
  methods:{
  	onChange(ev){
  		console.log(ev)
  	},
    goList(type){
      this.$router.push('/project-list?type='+type)
    }
  }
}
</script>
<style lang="less" scoped type="text/css">
	.project{
		position: relative;
    padding-top: 60px;
		.main{
			background: #f5f5f5;
      padding:20px 0 10px 0;
			margin: 0 auto;
			width: 100%;
			left: 0;
			top: 0;
			.content{
				width: 1200px;
				margin: 0 auto;
			}
      .cate-list{
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        .img{
          overflow: hidden;
          margin-bottom: 10px;
        }
        img{
          display: block;
          width: 100%;
          transition: all 0.6s;
          cursor: pointer;
          &:hover{
            transform: scale(1.1);
          }
        }
        .img1{
          width: 50%;
          padding-right: 5px;
        }
        .img2{
          width: 50%;
          padding-left: 5px;
        }
      }
		}
	}
</style>
