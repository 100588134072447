<template>
	<div class="project">
		<div class="main">
			<div class="content">
				<div class="title">
					<img src="@/assets/ques/ques.png"> 问题反馈
				</div>
				<div class="form">
					<a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
						<a-form-model-item label="姓名">
							<a-input v-model="form.name" :maxLength="10" size="large"
								placeholder="（选填）输入您的姓名或昵称，以便于我们对您的问题进行及时反馈" />
						</a-form-model-item>
						<a-form-model-item label="电话">
							<a-input v-model="form.phone" size="large" placeholder="（选填）输入您的联系电话，以便于我们对您的问题进行及时反馈" />
						</a-form-model-item>
						<a-form-model-item label="内容">
							<a-textarea style="font-size: 16px;" v-model="form.content" :maxLength="500"
								placeholder="（必填）请详细描述您在本站使用过程中遇到的问题，以便于我们对您的问题进行处理及反馈"
								:auto-size="{ minRows: 5, maxRows: 16 }" />
						</a-form-model-item>
					</a-form-model>
				</div>
				<div class="action">
					<div class="btn" @click="submit">
						提 &nbsp;&nbsp; 交
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import DwFilter from '@/components/DwFilter.vue'
	import DwImg from '@/components/DwImg.vue'
	export default {
		name: 'Ques',
		components: {
			DwFilter,
			DwImg
		},
		data() {
			return {
				labelCol: { span: 2 },
				wrapperCol: { span: 22 },
				form: {

				}
			}
		},
		methods: {
			submit() {
				if (!this.form.content || (this.form.content && this.form.content == '')) {
					this.$message.info('内容必填');
					return;
				}
				let params = {
					userName: this.form.name,
					telPhone: this.form.phone,
					content: this.form.content
				}

				this.$net.feedback(params).then(res => {
					if (res['isSuccess']) {
						this.$message.success('提交成功');
						setTimeout(() => {
							this.$router.back();
						}, 2000)
					} else {
						this.$message.error(res.resultMsg);
					}



				}).catch(err => {

				})
			},
			loadMore(ev) {
				console.log(ev)
			}
		}
	}
</script>
<style lang="less" scoped type="text/css">
	.project {
		position: relative;
		padding-top: 60px;
		.main {
			/* min-height: 100vh; */
			background: @second-color;
			padding: 10px 0 10px 0;
			margin: 0 auto;
			width: 100%;
			left: 0;
			top: 0;

			.content {
				width: 1200px;
				margin: 0 auto;
				background-color: #fff;
				padding: 60px;

				.title {
					font-size: 28px;
					color: @font-color;

					img {
						margin-right: 20px;
					}
				}

				.form {
					margin-top: 20px
				}

				.action {
					width: 100%;
					margin-top: 20px;

					.btn {
						width: 248px;
						height: 50px;
						line-height: 50px;
						text-align: center;
						background-color: @second-color;
						color: #fff;
						border-radius: 5px;
						font-size: 18px;
						margin: 0 auto;
						cursor: pointer;
					}
				}
			}

			.cate-list {
				width: 1100px;
				margin: 20px auto 20px auto;
				display: flex;

				.img {
					overflow: hidden;
					margin-bottom: 10px;
					width: 100%;
					height: 188px;
				}

				img {
					display: block;
					width: 100%;
					transition: all 0.6s;
					cursor: pointer;

					&:hover {
						transform: scale(1.1);
					}
				}

			}
		}
	}
</style>
<style lang="less" scoped type="text/css">
	@import url('@/mixin/base.less');
</style>